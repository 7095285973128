import React from 'react'

import styles from './MainPage.module.css'
import titleImg from '../asset/title.png'
import logoImg from '../asset/logo.png'
import weedImg from '../asset/weed.png'
import smokeImg from '../asset/smoke.png'
import img1 from '../asset/img1.png'
import bluntImg from '../asset/Bluntbar.png'
import img2 from '../asset/img2.png'

const MainPage = () => {
    return (
        <div className={styles['container']}>
            <div className={styles['smoke-bg-container']}>
                <div className={styles['title-img-container']}>
                    <img src={titleImg} alt="title" className={styles['title-img']} />
                </div>
                <div className={styles['first-content-container']}>
                    <div className={styles['first-content-left']}>
                        <img src={logoImg} alt="logo" className={styles['logo-img']} />
                    </div>
                    <div className={styles['first-content-right']}>
                        <p>Celebrate 4/20 with a fatty J</p>
                        <a href='https://twitter.com/BonedSolana' target="_blank" rel="noopener noreferrer">
                            <div className={styles['custom-button']}>
                                <img src={weedImg} alt="weed" className={styles['weed-img']} />
                                Twitter
                            </div>
                        </a>
                        <div className={styles['buffer-zone']}></div>
                        <a href='https://t.me/BonedSolana' target="_blank" rel="noopener noreferrer">
                            <div className={styles['custom-button']}>
                                <img src={weedImg} alt="weed" className={styles['weed-img']} />
                                Telegram
                            </div>
                        </a>
                        <p>CA: GLsefpawuMnkootraTnS3D2VMLgt3tU7UhT734Fq5nUc</p>
                    </div>
                    
                </div>
            </div>
            <div className={styles['second-content-container']}>
                <div className={styles['second-content-left']}>
                    We all want
                    <p>HIGHER</p>
                </div>
                <div className={styles['second-content-right']}>
                    <img src={img1} alt="img1" className={styles['img1']} />
                </div>
            </div>
            <div className={styles['third-content-container']}>
                <img src={bluntImg} alt="blunt" className={styles['blunt-img']} />
            </div>
            <div className={styles['forth-content-container']}>
                <div className={styles['forth-content-left']}>
                    <div className={styles['forth-content-left-box']}>
                        Grab one, or a million, we got 1,000,000,000 $BONED
                    </div>
                </div>
                <div className={styles['forth-content-right']}>
                    <img src={img2} alt="img2" className={styles['img2']} />
                </div>
            </div>
            <div className={styles['footer']}>
                @Boned 2024
            </div>
            <div className={styles['smoke-img-container']}>
                <img src={smokeImg} alt="smoke" className={styles['smoke-img']} />
            </div>
        </div>
    )
}

export default MainPage